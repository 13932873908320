import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import placeholder from "../images/placeholder.png";

export default function Profiles({ data }) {
  const page = data.wpPage;
  const profiles = page.profile.profiles;
  return (
    <Layout>
      <Seo seo={page.seo} />

      <div className="profile__heading__wrapper mt-6">
        <h3 className="profile__heading text-blue">{page.title}</h3>
      </div>

      <div className="wysiwyg__content__wrapper">
        <p
          className="wysiwyg__content profile__heading__wrapper mt-6 mb-6"
          dangerouslySetInnerHTML={{ __html: page.content }}
        />

        {profiles.map((profile, index) => (
          <div key={index} className="profile__wrapper">
            <div className="profile__image__wrapper">
              <img
                src={
                  profile.profileImage
                    ? profile.profileImage.sourceUrl
                    : placeholder
                }
                alt=""
                className="profile__image"
              />
            </div>

            <div className="profile__text">
              <p className="profile__details">
                <span className="profile__name">{profile.name}</span>
                <span> - </span>
                <span>{profile.role}</span>
              </p>
              <div
                className="wysiwyg__content"
                dangerouslySetInnerHTML={{ __html: profile.description }}
              />
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($databaseId: Int!) {
    wpPage(databaseId: { eq: $databaseId }) {
      id
      title
      slug
      uri
      content
      profile {
        profiles {
          description
          name
          profileImage {
            sourceUrl
          }
          role
        }
      }
      seo {
        fullHead
      }
    }
  }
`;
